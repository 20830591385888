/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.testimonials {
  padding: 24px 0;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 901px) {
  .testimonials {
    padding: 44px 0;
  }
}
.testimonials.testimonials--lineplay::after {
  background-image: url("../../../../img/svg/lineplay-testimonials.svg");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 800px;
  left: -40%;
  pointer-events: none;
  position: absolute;
  top: 40%;
  width: 500px;
  z-index: 0;
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  .testimonials.testimonials--lineplay::after {
    left: -24%;
  }
}
@media only screen and (min-width: 901px) {
  .testimonials.testimonials--lineplay::after {
    height: 1278px;
    left: calc(50% - 794px);
    top: -184px;
    width: 828px;
  }
}

.testimonials__content {
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}
@media only screen and (min-width: 901px) {
  .testimonials__content {
    padding: 0 120px;
    max-width: 1360px;
  }
}
@media only screen and (max-width: 359px) {
  .testimonials__content {
    padding: 0 12px;
  }
}

.testimonials__heading {
  color: #fe7000;
  font-size: 32px;
  line-height: 1.2;
  margin: 0 -23px 22px;
}
@media only screen and (min-width: 768px) {
  .testimonials__heading {
    margin: 0 0 37px;
  }
}
@media only screen and (max-width: 359px) {
  .testimonials__heading {
    margin: 0 0 22px;
  }
}

.testimonials__heading--secondary {
  color: #313136;
  display: block;
}

@media only screen and (min-width: 901px) {
  .testimonials__slider {
    overflow: hidden;
    margin: -5px;
    padding: 5px;
  }
}

.testimonial.swiper-slide {
  height: auto;
  justify-content: space-between;
}
@media only screen and (min-width: 901px) {
  .testimonial.swiper-slide {
    flex-direction: row;
  }
}

.testimonial__content {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  top: 0;
  transition-duration: 0.25s;
  transition-property: top, height;
  transition-timing-function: ease;
}
.testimonial__content::after {
  background-color: transparent;
  content: "";
  transition: background-color 0.25s ease;
}
@media only screen and (min-width: 901px) {
  .testimonial__content {
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  .testimonial:not(.swiper-slide-active) .testimonial__content::after {
    background-color: rgba(255, 255, 255, 0.6);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.testimonial:not(.swiper-slide-active) .testimonial__content {
  height: calc(100% - 40px);
  top: 40px;
}
@media only screen and (min-width: 901px) {
  .testimonial:not(.swiper-slide-active) .testimonial__content {
    height: 100%;
    top: 0;
  }
}

.testimonial__text {
  padding: 26px 25px 63px;
  position: relative;
  height: 100%;
}
@media only screen and (min-width: 901px) {
  .testimonial__text {
    flex: 1 1 60%;
    padding: 40px 40px 70px;
  }
}
@media only screen and (min-width: 1400px) {
  .testimonial__text {
    padding: 54px 48px 80px;
  }
}

.testimonial__image {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 66.6666666667%;
  position: relative;
  top: 0;
  transition: top 0.25s ease;
}
.testimonial__image img {
  width: 100%;
}
@media only screen and (min-width: 901px) {
  .testimonial__image {
    flex: 1 1 40%;
    max-height: 450px;
    height: 100%;
    padding-bottom: 0;
  }
  .testimonial__image img {
    height: 100%;
    object-fit: cover;
  }
}
.testimonial:not(.swiper-slide-active) .testimonial__image {
  top: 40px;
}
@media only screen and (min-width: 901px) {
  .testimonial:not(.swiper-slide-active) .testimonial__image {
    top: 0;
  }
}

.testimonial__title {
  font-size: 24px;
  line-height: 1.2;
  text-transform: none;
  margin: 0 0 9px;
}
@media only screen and (min-width: 901px) {
  .testimonial__title {
    font-size: 32px;
  }
}

.testimonial__subtitle {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 901px) {
  .testimonial__subtitle {
    font-size: 20px;
    line-height: 24px;
  }
}

.testimonial__icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 32px;
  margin-right: 12px;
  width: 32px;
}

.testimonial__description,
.testimonial__description p,
.testimonial__description a,
.testimonial__description span {
  font-size: 16px;
  line-height: 1.3;
  margin: 0;
}

.testimonials__slider-navigation {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 32px auto 0;
  max-width: 300px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 901px) {
  .testimonials__slider-navigation {
    justify-content: center;
    position: static;
  }
}
.testimonials__slider-navigation .swiper-button-disabled {
  opacity: 0;
}

.testimonials__slider-pagination {
  position: static;
}
.testimonials__slider-pagination .swiper-pagination-bullet {
  cursor: pointer;
  width: 20px;
  height: 4px;
  display: inline-block;
  border-radius: 3px;
  background: #efefef;
  opacity: 1;
  margin: auto 11px;
  transition: all 0.2s ease-in-out;
}
.testimonials__slider-pagination .swiper-pagination-bullet:first-child {
  margin-left: 0;
}
.testimonials__slider-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.testimonials__slider-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fe7000;
  width: 38px;
  height: 6px;
}

.testimonial__location {
  bottom: 18px;
  left: 28px;
  display: flex;
  align-items: center;
  position: absolute;
}
@media only screen and (min-width: 901px) {
  .testimonial__location {
    bottom: 30px;
    left: 30px;
  }
}
@media only screen and (min-width: 1400px) {
  .testimonial__location {
    bottom: 48px;
    left: 52px;
  }
}

.testimonial__location-icon {
  color: #fe7000;
  display: inline-block;
  margin-right: 12px;
  font-size: 20px;
}

.testimonials__slider-arrow--prev {
  left: 30px;
  margin-right: 9px;
}
.testimonials__slider-arrow--prev::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f136";
  color: #2d2d2d;
  display: inline-block;
  font-size: 16px;
  height: 16px;
  line-height: initial;
  position: relative;
  transform: scale(-1, 1);
  vertical-align: middle;
  width: 16px;
  top: -2px;
}

.testimonials__slider-arrow--next {
  margin-left: 9px;
  right: 30px;
  text-align: right;
}
.testimonials__slider-arrow--next::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f136";
  display: inline-block;
  position: relative;
  font-size: 16px;
  vertical-align: middle;
  line-height: initial;
  color: #2d2d2d;
  width: 16px;
  height: 16px;
  top: -2px;
}

.testimonials__slider-arrow {
  cursor: pointer;
}
@media only screen and (min-width: 901px) {
  .testimonials__slider-arrow {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    height: 64px;
    padding: 20px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 64px;
    z-index: 2;
    transition: scale 0.1s ease-in-out;
  }
  .testimonials__slider-arrow:hover {
    scale: 1.1;
  }
}