@use "sass:math";

.testimonials {
    padding: 24px 0;
    position: relative;
    overflow: hidden;

    @include mq($not-mobile-tablet-pt) {
        padding: 44px 0;
    }

    &.testimonials--lineplay::after {
        background-image: url("../../../../img/svg/lineplay-testimonials.svg");
        background-position: right top;
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        height: 800px;
        left: -40%;
        pointer-events: none;
        position: absolute;
        top: 40%;
        width: 500px;
        z-index: 0;

        @include mq($tablet-pt) {
            left: -24%;
        }

        @include mq($not-mobile-tablet-pt) {
            height: 1278px;
            left: calc(50% - 794px);
            top: -184px;
            width: 828px;
        }
    }
}

.testimonials__content {
    margin: 0 auto;
    padding: 0 40px;
    position: relative;

    @include mq($not-mobile-tablet-pt) {
        padding: 0 120px;
        max-width: 1360px;
    }

    @include mq($mobile-xs) {
        padding: 0 12px;
    }
}

.testimonials__heading {
    color: $safety-orange;
    font-size: 32px;
    line-height: 1.2;
    margin: 0 -23px 22px;

    @include mq($not-mobile) {
        margin: 0 0 37px;
    }

    @include mq($mobile-xs) {
        margin: 0 0 22px;
    }
}

.testimonials__heading--secondary {
    color: $black;
    display: block;
}

.testimonials__slider {
    @include mq($not-mobile-tablet-pt) {
        overflow: hidden;
        margin: -5px;
        padding: 5px;
    }
}

.testimonial.swiper-slide {
    height: auto;
    justify-content: space-between;

    @include mq($not-mobile-tablet-pt) {
        flex-direction: row;
    }
}

.testimonial__content {
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
    top: 0;
    transition-duration: 0.25s;
    transition-property: top, height;
    transition-timing-function: ease;

    &::after {
        background-color: transparent;
        content: "";
        transition: background-color 0.25s ease;
    }

    @include mq($not-mobile-tablet-pt) {
        flex-direction: row;
        justify-content: flex-start;
    }

    @include mq($tablet-pt) {
        .testimonial:not(.swiper-slide-active) & { // on tablet, inactive slides are partially visible, but have overlay
            &::after {
                background-color: rgba($white, 0.6);
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

    .testimonial:not(.swiper-slide-active) & {
        height: calc(100% - 40px);
        top: 40px;

        @include mq($not-mobile-tablet-pt) {
            height: 100%;
            top: 0;
        }
    }
}

.testimonial__text {
    padding: 26px 25px 63px;
    position: relative;
    height: 100%;

    @include mq($not-mobile-tablet-pt) {
        flex: 1 1 60%;
        padding: 40px 40px 70px;
    }

    @include mq($desktop-xxl) {
        padding: 54px 48px 80px;
    }
}

.testimonial__image {
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: math.percentage(math.div(2, 3));
    position: relative;
    top: 0;
    transition: top 0.25s ease;

    img {
        width: 100%;
    }

    @include mq($not-mobile-tablet-pt) {
        flex: 1 1 40%;
        max-height: 450px;
        height: 100%;
        padding-bottom: 0;

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    .testimonial:not(.swiper-slide-active) & {
        top: 40px;

        @include mq($not-mobile-tablet-pt) {
            top: 0;
        }
    }
}

.testimonial__title {
    font-size: 24px;
    line-height: 1.2;
    text-transform: none;
    margin: 0 0 9px;

    @include mq($not-mobile-tablet-pt) {
        font-size: 32px;
    }
}

.testimonial__subtitle {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 18px;
    display: flex;
    align-items: center;

    @include mq($not-mobile-tablet-pt) {
        font-size: 20px;
        line-height: 24px;
    }
}

.testimonial__icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 32px;
    margin-right: 12px;
    width: 32px;
}

.testimonial__description {
    &,
    p,
    a,
    span {
        font-size: 16px;
        line-height: 1.3;
        margin: 0;
    }
}

.testimonials__slider-navigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 32px auto 0;
    max-width: 300px;
    position: relative;
    z-index: 1;

    @include mq($not-mobile-tablet-pt) {
        justify-content: center;
        position: static;
    }

    .swiper-button-disabled {
        opacity: 0;
    }
}

.testimonials__slider-pagination {
    position: static;

    .swiper-pagination-bullet {
        cursor: pointer;
        width: 20px;
        height: 4px;
        display: inline-block;
        border-radius: 3px;
        background: $white-smoke;
        opacity: 1;
        margin: auto 11px;
        transition: all 0.2s ease-in-out;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: $safety-orange;
        width: 38px;
        height: 6px;
    }
}

.testimonial__location {
    bottom: 18px;
    left: 28px;
    display: flex;
    align-items: center;
    position: absolute;

    @include mq($not-mobile-tablet-pt) {
        bottom: 30px;
        left: 30px;
    }

    @include mq($desktop-xxl) {
        bottom: 48px;
        left: 52px;
    }
}

.testimonial__location-icon {
    color: $safety-orange;
    display: inline-block;
    margin-right: 12px;
    font-size: 20px;
}

.testimonials__slider-arrow--prev {
    left: 30px;
    margin-right: 9px;

    &::before {
        @include iconfont-styles;

        content: svg(swiper-arrow);
        color: $nero;
        display: inline-block;
        font-size: 16px;
        height: 16px;
        line-height: initial;
        position: relative;
        transform: scale(-1, 1);
        vertical-align: middle;
        width: 16px;
        top: -2px;
    }
}

.testimonials__slider-arrow--next {
    margin-left: 9px;
    right: 30px;
    text-align: right;

    &::after {
        @include iconfont-styles;

        content: svg(swiper-arrow);
        display: inline-block;
        position: relative;
        font-size: 16px;
        vertical-align: middle;
        line-height: initial;
        color: $nero;
        width: 16px;
        height: 16px;
        top: -2px;
    }
}

.testimonials__slider-arrow {
    cursor: pointer;

    @include mq($not-mobile-tablet-pt) {
        background-color: $white;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
        height: 64px;
        padding: 20px;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 64px;
        z-index: 2;
        transition: scale 0.1s ease-in-out;

        &:hover {
            scale: 1.1;
        }
    }
}
